<script setup>
import { ref, onMounted } from "vue";
import router from "@/router.js";
import { beesDatasList, getListItems } from "@/utils/market";
import { checkWorkBees, collectWorkBees, getBalance, load, profileData } from "@/utils/profile";
import { getTranslate, loadLocalize } from "@/utils/user";
import { showShareModal } from "@/utils/share";
import { getListNotifications } from "@/utils/notification";
import { tasks } from "@/utils/localize";

const myBees = ref('loading...')
const staking = ref('loading...')
const gatherHoney = ref('loading...')

const isCanCollect = ref(null);
const userBees = ref([]);

const loadTranslations = async () => {
    myBees.value = await getTranslate('my_bees');
    staking.value = await getTranslate('stake');
    gatherHoney.value = await getTranslate('gather_honey');
};

const openTasks = async () => {
    await router.push({
        name: "tasks"
    });
};

const openMyBees = async () => {
    await router.push({
        name: "mybees"
    });
};

const openStacking = async () => {
    await router.push({
        name: "stacking"
    });
};

const getRandomPosition = () => {
    const maxX = 80;
    const maxY = 70;
    const x = Math.random() * maxX;
    const y = Math.random() * maxY;
    return { x, y };
};

const getRandomTopWithParams = (min, max) => {
    return Math.floor(Math.random() * max + min) + "vh";
};

const getRandomImageWithParams = (min, max) => {
    return Math.floor(Math.random() * max + min);
};

const getRandomDelayWithParams = (min, max) => {
    return Math.floor(Math.random() * max + min) + "s";
};

const getRandomDurationWithParams = (min, max) => {
    return Math.random() * max + min + "s";
};

const getRandomDuration = () => {
    return Math.random() * 10 + 5 + "s";
};

const getRandomDelay = () => {
    return Math.random() * 5 + "s";
};

const getRandomDirection = () => {
    return Math.random() < 0.5 ? 1 : -1;
};

const enableOverlay = (isEnable) => {
    const overlay = document.getElementById('overlay');

    if ((overlay !== null)) {
        overlay.style.display = isEnable ? 'block' : 'none';
    }
}

const collectHoney = async () => {
    enableOverlay(true);
    await collectWorkBees();
    let user = await checkWorkBees();
    await updateBeesOnScreen(user);
    let balance = await getBalance();
    document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
    document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
    enableOverlay(false);

    showShareModal();
};

const updateBeesOnScreen = async (user) => {
    userBees.value = [];
    let menu = document.getElementById("main_menu");
    await getListItems();

    isCanCollect.value = false;

    removeBees();

    if (menu !== null) {
        user.bees?.forEach(bee => {
            userBees.value.push(bee);
            // console.log(JSON.stringify(bee));

            let beeType = beesDatasList.value.find(data => data.beeData._id === bee.id)?.beeData.type || 'default';
            let beesUnderHeader = document.getElementById('bees_under_header');

            if (beesUnderHeader !== null) {
                if (bee.count > 0 && bee.is_fly) {
                    const { x, y } = getRandomPosition();
                    const duration = getRandomDuration();
                    const delay = getRandomDelay();
                    const direction = getRandomDirection();

                    const beeImg = document.createElement('img');
                    
                    beeImg.classList.add('bee');
                    bee.id = `${beeType}`;
                    beeImg.src = `bees/${beeType}.gif`;
                    beeImg.alt = `bee-${beeType}`;
                    beeImg.style.left = `${x}vw`;
                    beeImg.style.top = `${y}vh`;
                    beeImg.style.animationDuration = duration;
                    beeImg.style.animationDelay = delay;
                    beeImg.style.transform = `scaleX(${direction})`;

                    menu.appendChild(beeImg);
                    
                    beesUnderHeader.innerHTML += 
                        `<div class="bee_cell">
                            <img class="bee_icon" src="bees/${beeType}.gif" alt="bee-${beeType}" /> 
                        </div>`

                    if (bee.is_can_collect) {
                        isCanCollect.value = true;
                    }
                }
            }
        });
    }
};

const removeBees = () => {
    let bees = document.getElementsByClassName('bee');
    while(bees.length > 0){
        bees[0].parentNode.removeChild(bees[0]);
    }

    let beesHeader = document.getElementsByClassName('bee_cell');
    while(beesHeader.length > 0){
        beesHeader[0].parentNode.removeChild(beesHeader[0]);
    }
}

const spawnClounds = () => {
    let menu = document.getElementById("main_menu");
    
    if (menu !== null) {
        for (let i = 1; i <= 12; i++) {
            const duration = getRandomDurationWithParams(15, 30);
            const delay = i == 0 ? getRandomDelayWithParams(0, 0) : getRandomDelayWithParams(3, 10);
            const top = getRandomTopWithParams(-1, 0);
            const image = getRandomImageWithParams(1, 4);

            const cloudImg = document.createElement('img');

            cloudImg.classList.add('cloud');
            cloudImg.src = `clouds/cloud_${image}.png`;
            cloudImg.alt = `cloud_${i}`;
            cloudImg.style.top = top;
            cloudImg.style.animationDuration = duration;
            cloudImg.style.animationDelay = delay;
            cloudImg.style.transform = `translateX(-120%) translateY(${top})`;

            menu.appendChild(cloudImg);
        }
    }
};

onMounted(async () => {
    await loadLocalize();
    await loadTranslations();
    await load();

    if (profileData.value && profileData.value.serverData) {
        let loadUser = profileData.value.serverData;
        let user = await checkWorkBees();

        if (user.boosters[0].is_active) {
            if (JSON.stringify(user.bees) !== JSON.stringify(loadUser.bees)) {
                showShareModal();
            }
        }

        let balance = await getBalance();
        await getListNotifications();
        document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
        document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
        await updateBeesOnScreen(user);
    }
    spawnClounds();
});
</script>

<template>
<main id="main_menu" class="main-menu">
    <div id="overlay" class="overlay"></div>
    <div class="side_panel">
        <button id="side-button-tasks" class="side-button side-button-brown" @click="openTasks">
            <img class="side-icons" src="../../assets/images/scroll-info.png" alt="tasks" />
            <p>{{ tasks }}</p>
        </button>

        <button id="side-button-closet" class="side-button side-button-brown" @click="openMyBees">
            <img class="side-icons" src="../../assets/images/closet.png" alt="closet" />
            <p>{{ myBees }}</p>
        </button>

        <button id="side-button-staking" class="side-button side-button-green" @click="openStacking">
            <img class="side-icons" src="../../assets/images/bank.png" alt="bank" />
            <p>{{ staking }}</p>
        </button>
    </div>
    <template v-if="isCanCollect">
        <button class="gather-honey" @click="collectHoney">
            <p>{{ gatherHoney }}</p>
        </button>
    </template>
</main>
</template>

<style>
@keyframes moveClouds {
    0% {
        transform: translateX(-120%);
    }
    100% {
        transform: translateX(120%);
    }
}

@keyframes flyBee {
    0% {
        transform: translateX(0) translateY(0) scaleX(1);
    }
    25% {
        transform: translateX(5vw) translateY(-3vh) scaleX(1);
    }
    49.9% {
        transform: translateX(9.99vw) translateY(-0.01vh) scaleX(1);
    }
    50% {
        transform: translateX(10vw) translateY(0) scaleX(-1);
    }
    75% {
        transform: translateX(5vw) translateY(3vh) scaleX(-1);
    }
    100% {
        transform: translateX(0) translateY(0) scaleX(-1);
    }
}

@keyframes gatherHoney {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.cloud {
    position: absolute;
    transform: translateX(-120%);
    width: 40vw;
    height: auto;
    z-index: 2;
    animation: moveClouds 30s linear infinite;
}

.bee {
    position: absolute;
    width: 12vw;
    z-index: 1;
    animation: flyBee linear infinite;
}

.side-icons {
    width: 8vw;
    height: 8vw;
}

.main-menu {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10vh;
}

.side_panel {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 1vh;
    position: absolute;
    top: 20%;
    z-index: 10;
} 

.side-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2vw;
    font-size: 85%;
    color: #fff;
    background-repeat: round;
    background-size: cover;
    width: fit-content;
    height: min-content;
    padding: 1%;
    padding-left: 4%;
}

.side-button p {
    font-size: 0%;
    visibility: hidden;
}
.side-button:hover p {
    font-size: 85%;
    visibility: visible;
}

.side-button:hover {
    transform: translateX(-1px);
    transform: scale3d(10px);
}

.side-button:active p {
    font-size: 85%;
    visibility: visible;
}

.side-button:active {
    transform: translateX(-1px);
    transform: scale3d(10px);
}

.side-button-brown {
    background-image: url('../../assets/images/side_button_brown.png');
}

.side-button-green {
    background-image: url('../../assets/images/side_button_green.png');
}

.gather {
    width: 40vw;
    height: 20vw;
    padding: 0vw;
    color: #fff;
    background-image: url('../../assets/images/button_bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    font-size: 4vw;
    text-align: center;
    position: absolute;
    top: 65%;
    z-index: 10;
}

.gather-honey {
    width: 40vw;
    height: 20vw;
    color: #fff;
    background-image: url('../../assets/images/add-honey.png');
    background-size: 100%;
    background-repeat: no-repeat;
    font-size: 4vw;
    text-align: center;
    position: absolute;
    top: 65%;
    z-index: 10;
    animation: gatherHoney 0.5s linear infinite;
}
</style>