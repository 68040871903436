import { ref } from "vue";
import { getRequest } from "./restapi";
import { tgUserAccount } from "./telegram";
import { API_ADDRESS_SERVER } from "@/config";

export const account = ref(null);
export const accountId = ref(null);
export const accountName = ref(null)
export const accountLang = ref(null)
export const userData = ref(null);

export const localizes = ref([])

export const updateData = async () => {
    account.value = tgUserAccount;
    accountId.value = account.value.id;
    accountName.value = account.value.username;
    accountLang.value = account.value.language_code;
    userData.value = await authorization(accountId.value, accountName.value, accountLang.value);
    localStorage.setItem("AUTH", userData.value.key);
    return userData.value;
};

export const changeLanguage = async (id, lang) => await getRequest(`${API_ADDRESS_SERVER}/user/language/${id}/${lang}`, false);

export const loadLocalize = async () => {
    localizes.value = await getRequest(`${API_ADDRESS_SERVER}/localization/get/all`, false);
};

export const getTranslate = async (key) => {
    let result = key;

    localizes.value.forEach(loc => {
        if (loc.key === key) {
            let lang = userData.value?.language ?? accountLang.value
            if (lang === 'ru') {
                result = loc.value.ru;
            } else {
                result = loc.value.en;
            }
        }
    });

    return result;
} 

export const shareForUser = async () => getRequest(`${API_ADDRESS_SERVER}/user/share/${accountId.value}`, false);
export const getUserIncome = async () => getRequest(`${API_ADDRESS_SERVER}/user/income/${accountId.value}`, false);

const authorization = async (id, username, lang) => await getRequest(`${API_ADDRESS_SERVER}/user/authorization/${id}/${username}/${lang}`, false);