<script setup>
import { onMounted, ref } from "vue";
import { getBalance, load, profileData } from "@/utils/profile";
import { checkCompliteTask, getListTasks, tasksList } from "@/utils/tasks";
import { check, denied, done, error_status, loadLoc, reward, subscribe, сhecking } from "@/utils/localize";
import { tg } from "@/utils/telegram";

const tasks = ref(null);
const tasksStatus = ref([]);

const preventCollapse = () => {
    if (window.scrollY === 0) {
        window.scrollTo(0, 1);
    }
};

const loadTasks = async () => {
    await getBalance();
    await load();
};

const checkTask = async (taskData) => {
    let text = document.getElementById(`text_check_task_${taskData._id}`)
    let button = document.getElementById(`button_check_task_${taskData._id}`)
    text.innerText = сhecking.value;
    button.disabled = true;

    let data = await checkCompliteTask(taskData);

    if (data != null) {
        if (data.status == "member") {
            let task = tasksStatus.value.find(taskStatus => taskStatus.task_id == taskData._id);
            task.is_complete = true;
            let balance = await getBalance();
            document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
            document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
        } else if (data.status == "left") {
            text.innerText = check.value;
            button.disabled = false;
            window.location = taskData.link;
        } else if (data.status == "kicked") {
            if (tg.platform === "unknown") {
                alert("You blocked!");
            } else {
                tg.showAlert("You blocked!");
            }
            console.error("User is blocked!");
            text.innerText = denied.value;
            button.className += " lock";
            button.disabled = true;
        } else {
            if (tg.platform === "unknown") {
                alert(JSON.stringify(data));
            } else {
                tg.showAlert(JSON.stringify(data));
            }
            // console.log(JSON.stringify(data));
            text.innerText = error_status.value;
            button.className += " lock";
            button.disabled = true;
        }
    } else {
        if (tg.platform === "unknown") {
            alert("The bot has not been added to the channel. Please write to the administrator and try again a little later.....");
        } else {
            tg.showAlert("The bot has not been added to the channel. Please write to the administrator and try again a little later.....");
        }
        console.error("The bot has not been added to the channel. Please write to the administrator and try again a little later.....");
        text.innerText = error_status.value;
        button.className += " lock";
        button.disabled = true;
    }
};

const setTasksStatus = async () => {
    await load();
    tasks.value = tasksList.value;
    let completedTasks = profileData.value.serverData.completed_tasks;

    tasks.value.forEach(task => {
        let res = completedTasks?.find(comTask => comTask.task_id == task.taskData._id);
        tasksStatus.value.push({
            task_id: task.taskData._id,
            is_complete: res != null
        })
    });
};

const init = async () => {
    await loadLoc();
    await loadTasks();
    await getListTasks();
    await setTasksStatus();
};

onMounted(async () => {
    await init();

    const scrollableElement = document.querySelector(".scrollable-element");
    scrollableElement.addEventListener("touchstart", preventCollapse);
});
</script>

<template>
    <main class="tasks-background">
        <div id="overlay" class="overlay"></div>
        <div id="task-animation" class="task-animation"></div>
        <div class="tasks-content-container scrollable-element" id="tasks">
            <div class="tasks-element" 
                v-for="(task, index) in tasks" 
                :key="index" 
                id="tasks-item"
            >
                <div class="name-task">
                    <div class="properties">
                        <span class="properties-task">{{subscribe}}</span>
                        <span class="properties-task yellow">{{ task.taskData.name }}</span>
                    </div>
                </div>
                <div class="reward">
                    <span>{{reward}}</span>
                    <span class="yellow">{{ task.taskData.reward }}</span>
                    <img class="icon" src="../../assets/images/honey.png" alt="honey" />
                </div>
                <div class="price">
                    
                </div>
                <template v-if="tasksStatus[index].is_complete">
                    <button class="btn-task lock" disabled>
                        <span>{{done}}</span> 
                    </button>
                </template>
                <template v-else>
                    <button :id="`button_check_task_${task.taskData._id}`" class="btn-task" @click="checkTask(task.taskData)">
                        <span :id="`text_check_task_${task.taskData._id}`">{{check}}</span> 
                    </button>
                </template>
            </div>
        </div>
    </main>
</template>

<style>
.tasks-background {
    background-image: url("../../assets/images/scroll-info.png");
    background-size: cover;
    background-repeat: round;
    height: 80%;
    width: 90vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 17vw 0 17vw 0;
    object-fit: contain;
    overflow: hidden;
}

.tasks-content-container {
    width: 65vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2vw;
    overflow: overlay;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-right: 2vw;
}

.tasks-element {
    background-image: url('../../assets/images/back_small_scroll_2.png');
    background-size: cover;
    background-repeat: round;
    width: -webkit-fill-available;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10%;
}

.btn-task {
    width: 18vw;
    height: 8vw;
    background-image: url('../../assets/images/button_bg.png');
    background-repeat: round;
    background-size: cover;
    padding: 5vw;
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #753f15;
    font-family: PIXY;
    font-size: 3vw;
}

.lock {
    border-radius: 5px;
    box-shadow:inset 0px 0px 15px 5px #1b1b1b4d;
}

.properties {
    display: flex;
    flex-direction: column;    
    color: #753f16;
    font-size: 3vw;
}

.properties-task {
    font-size: 5vw;
}

.name-task {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.task-animation {
    position: fixed;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1000;
    display: none;
    transform: translate(0, 0) rotate(0);
}

.yellow {
    color: yellow;
    font-weight: bold;
}

.reward {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 4vw;
    color: #753f15;
    font-family: PIXY;
    gap: 5%;
}
</style>