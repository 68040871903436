import { ref } from "vue";
import { putRequest, putWithBodyRequest } from "./restapi";
import { sendTransaction } from "./tonConnect";
import { API_ADDRESS_SERVER } from "@/config";
import { load } from "./profile";

export const enableWithdraw = ref(false);

export const showDepositModal = async () => {
    await load();
    document.getElementById("depositModal")?.show();
};

export const hideDepositModal = () => document.getElementById("depositModal")?.close()

export const makeDeposit = async (idUser, amount) => {
    let transactionResult = await sendTransaction(amount, `Deposit ${amount}`);
   
    if (transactionResult !== null) {
        console.log(transactionResult.boc);
        let result = await putWithBodyRequest(`${API_ADDRESS_SERVER}/deposit/ton/${idUser}/${amount}`, transactionResult);
        console.log(result);
    }
};

export const withdrawToDeposit = async (idUser) => {
    await putRequest(`${API_ADDRESS_SERVER}/deposit/income/ton/${idUser}`)
};