<script setup>
import { tg } from "@/utils/telegram";
import { currentIsConnectedStatus } from "@/utils/tonConnect";
import { connectToWallet, getBalance, load, profileData, requestProfileData } from "@/utils/profile";
import { createPaymentTON } from '@/utils/income';
import { hideDepositModal, makeDeposit } from "@/utils/deposit";
import HomeButton from "@/components/HomeButton.vue"
import { defense_against_laundering, deposit_account, deposit_deposit, deposit_info, deposit_withdraw, error_connect_wallet, error_empty_amount_deposit, error_empty_amount_withdraw, error_min_deposit, error_min_withdraw, invite_one_friend } from "@/utils/localize";
import { getSetting } from "@/utils/settings";

const toDeposit = async () => {
    await load();

    if (!currentIsConnectedStatus.value) {
        if (tg.platform === "unknown") {
            alert(error_connect_wallet.value);
        } else {
            tg.showAlert(error_connect_wallet.value, async () => await connectToWallet());
        }
        await connectToWallet();
    } else {
        let amount = document.getElementById("deposit_input")?.value ?? "";

        if (amount !== "" && amount !== " ") {
            if (amount >= 0.01) {
                await makeDeposit(profileData.value.serverData._id, amount);
                let balance = await getBalance();
                document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
                document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
                hideDepositModal();
            } else {
                tg.showAlert(error_min_deposit.value);
            }
        } else {
            tg.showAlert(error_empty_amount_deposit.value);
        }
    }
};

const fromDeposit = async () => {
    await requestProfileData();

    if (!currentIsConnectedStatus.value) {
        if (tg.platform === "unknown") {
            alert(error_connect_wallet.value);
        } else {
            tg.showAlert(error_connect_wallet.value, async () => await connectToWallet());
        }
        await connectToWallet();
    } else {
        let amount = document.getElementById("deposit_input")?.value ?? "";

        if (amount !== "" && amount !== " ") {
            if (amount >= 0.01) {
                let withheld = profileData.value.serverData.balance.withheld.ton;
                let canWithdraw = Number(profileData.value.serverData.balance.ton - withheld);
                let canUse = await getSetting('use_protection_anti_washing');
                // alert(`CAN? ${canUse.value === 'false'} | withheld: ${withheld} | canWithdraw: ${canWithdraw} | withheld <= 0 ${withheld <= 0} | amount <= canWithdraw ? ${amount <= canWithdraw}`);
                if ((withheld <= 0) || (amount <= canWithdraw) || (canUse.value === 'false')) {
                    await createPaymentTON(amount);
                    await requestProfileData();
                    let balance = await getBalance();
                    document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
                    document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
                    hideDepositModal();
                } else {
                    let mesage = defense_against_laundering.value
                                .replace("{value_1}", withheld)
                                .replace("{value_2}", Number(canWithdraw.toFixed(3)));

                    if (tg.platform === "unknown") {
                        alert(mesage);
                    } else {
                        tg.showAlert(mesage);
                    }
                }
            } else {
                tg.showAlert(error_min_withdraw.value);
            }
        } else {
            tg.showAlert(error_empty_amount_withdraw.value);
        }
    }
};
</script>

<template>
<dialog id="depositModal" class="modal">
    <div class="modal-window">
        <div class="popup">
            <h1 class="popup_header">{{ deposit_account }}</h1>
            <p class="popup_content">{{ deposit_info }}</p>
            <input id="deposit_input" class="popup_input" placeholder="0 TON" type="number"/>
            <div class="popup_buttons_panel">
                <button class="popup_button" @click="toDeposit">
                    {{ deposit_deposit }}
                </button>
                <template v-if="profileData?.countInvited > 0">
                    <button class="popup_button" @click="fromDeposit">
                        {{ deposit_withdraw }}
                    </button>
                </template>
                <template v-else>
                    <button class="popup_button">
                        {{ invite_one_friend }}
                    </button>
                </template>
            </div>
        </div>
        <HomeButton isModal="true"/>
    </div>
</dialog>
</template>

<style>
.modal {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-color: transparent;
}

.modal-window {
    background-color: transparent;
    background-image: url("../../assets/images/scroll-info.png");
    background-repeat: round;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    width: 90%;
    max-width: 70vw;
}

.popup_header {
    font-size: 6vw;
    font-family: PIXY;
    color: #753f16;
    text-align: center;
    margin-bottom: 1rem;
}

.popup_content {
    font-size: 4.5vw;
    font-family: PIXY;
    color: #753f16;
    text-align: center;
    margin-bottom: 1rem;
}

.popup_input {
    background-image: url("../../assets/images/input.png");
    background-size: cover;
    background-repeat: round;
    font-size: 3.5vw;
    padding: 1rem;
    color: #753f16;
    background-color: transparent;
    border: 1px solid #753f16;
    border-radius: 45px;
    margin-bottom: 1rem;
    border-color: transparent;
    /* width: 100%; */
    box-sizing: border-box;
}

.popup_buttons_panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
}

.popup_button {
    color: #753f16;
    background-image: url("../../assets/images/button_bg.png");
    background-size: cover;
    background-repeat: round;
    font-size: 4.5vw;
    font-family: PIXY;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    max-width: 50vw;
    height: 15vw;
    text-align: center;
    box-sizing: border-box;
}

@media (min-width: 600px) {
    .popup_header {
        font-size: 2rem;
    }

    .popup_content {
        font-size: 1.75rem;
    }

    .popup_input {
        font-size: 1.5rem;
    }

    .popup_button {
        font-size: 1.25rem;
    }
}
</style>