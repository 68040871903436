import { createRouter, createWebHistory } from 'vue-router'
import LoadView from '@/views/LoadView.vue'
import SoonView from '@/views/SoonView.vue'
import MainView from '@/views/MainView.vue'
import MarketView from '@/views/MarketView.vue'
import TasksView from './views/TasksView.vue'
import BarrelView from '@/views/BarrelView.vue'
import InfoView from '@/views/InfoView.vue'
import RoadMapView from '@/views/RoadMapView.vue'
import ProfileView from '@/views/ProfileView.vue'
import MyBeesView from '@/views/MyBeesView.vue'
import StackingView from '@/views/StackingView.vue'

const routes = [
  {
    path: '/',
    name: 'load',
    component: LoadView
  },
  {
    path: '/soon',
    name: 'soon',
    component: SoonView
  },
  {
    path: '/home',
    name: 'home',
    component: MainView
  },
  {
    path: '/market',
    name: 'market',
    component: MarketView
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView
  },
  {
    path: '/info',
    name: 'info',
    component: InfoView
  },
  {
    path: '/barrel',
    name: 'barrel',
    component: BarrelView
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: RoadMapView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/mybees',
    name: 'mybees',
    component: MyBeesView
  },
  {
    path: '/stacking',
    name: 'stacking',
    component: StackingView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
