<script setup>
import { ref, onMounted } from "vue";
import { updateData } from '@/utils/user';
import { beesDatasList, getListItems } from "@/utils/market";
import HomeButton from "@/components/HomeButton.vue"

const userBees = ref([]);

const updateBeesOnScreen = async (user) => {
    userBees.value = [];
    let woodchest = document.getElementById("woodchest");

    if (woodchest !== null) {
        await getListItems();

        user.bees.forEach(bee => {
            userBees.value.push(bee);
            // console.log(JSON.stringify(bee));
        });

        for (let index = 0; index < user.bees.length; index += 2) {
            woodchest.innerHTML += `
                    <div id="polka" class="polka">
                        <div id="polka_first_${index}" class="polkaCell">
                            <p id="counter_first_${index}" class="counter"></p>
                            <img class="bottle" src="images/bottle.png">
                        </div>
                        <div id="polka_second_${index}" class="polkaCell">
                            <p id="counter_second_${index}" class="counter"></p>
                            <img class="bottle" src="images/bottle.png">
                        </div>
                    </div>`;
            
                let firstBee = user.bees[index];
                let secondBee = user.bees[index + 1] || { count: 0, id: null };

                let firstBeeType = beesDatasList.value.find(data => data.beeData._id === firstBee.id)?.beeData.type || 'default';
                let secondBeeType = beesDatasList.value.find(data => data.beeData._id === secondBee.id)?.beeData.type || 'default';
                
                let countUnactiveFirstBees = (firstBee.is_fly && firstBee.count > 0) 
                                            ? firstBee.count - 1 
                                            : firstBee.count;
                let countUnactiveSecondBees = 0;
                
                if (secondBee.count > 0) {
                    countUnactiveSecondBees = secondBee.is_fly
                                            ? secondBee.count - 1 
                                            : secondBee.count;
                }

                let polka_first = document.getElementById(`polka_first_${index}`);
                let polka_second = document.getElementById(`polka_second_${index}`);

                if (polka_first !== null && polka_second !== null) {
                    polka_first.innerHTML += `${countUnactiveFirstBees > 0 ? `<img class="bee_in_bottle" src="bees/${firstBeeType}.gif">` : ''}`;
                    polka_second.innerHTML += `${countUnactiveSecondBees > 0 ? `<img class="bee_in_bottle" src="bees/${secondBeeType}.gif">` : ''}`;

                    let counter_first = document.getElementById(`counter_first_${index}`);
                    let counter_second = document.getElementById(`counter_second_${index}`);
                    
                    if (counter_first !== null && counter_second !== null) {
                        counter_first.innerHTML = `x${countUnactiveFirstBees}`;
                        counter_second.innerHTML = `x${countUnactiveSecondBees}`;
                    }
                }
        }
    }
};

const preventCollapse = () => {
  if (window.scrollY === 0) {
    window.scrollTo(0, 1);
  }
};

onMounted(async () => {
    let user = await updateData();
    // console.log(JSON.stringify(user));
    
    updateBeesOnScreen(user);

    const scrollableElement = document.querySelector(".scrollable-element");
    scrollableElement.addEventListener("touchstart", preventCollapse);
});
</script> 

<template>
    <main id="woodchest" class="back_mybees scrollable-element">
    </main>
    <HomeButton isModal="false"/>
</template>

<style>
    ::-webkit-scrollbar-thumb {
        box-shadow: inset 5px 0px 5px 2px #ffffff24;
        background-color: #d78b4c;
        border-radius: 45px;
    }

    ::-webkit-scrollbar-track {
        background-color: #8b3f1f;
        border-radius: 45px;
    }

    .back_mybees {
        width: 90vw;
        display: flex;
        background-size: cover;
        justify-content: flex-start;
        flex-direction: column;
        background-repeat: round;
        flex-wrap: nowrap;
        align-items: center;
        overflow: overlay;
    }

    .polka {
        width: 75%;
        height: -webkit-fill-available;
        background-image: url("@/assets/images/polka.png");
        background-size: auto;
        background-repeat: round;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: space-around;
    }

    .polkaCell {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding-bottom: 13%;
        position: relative;
    }

    .counter {
        z-index: 3;
        color: white;
        font-size: 7vw;
    }

    .bottle {
        z-index: 2;
        width: 15vw;
    }

    .bee_in_bottle {
        z-index: 1;
        width: 15vw;
        position: absolute;
        transform: translateY(0) rotate(-20deg);
        top: 50%;
        animation: beeInBottle 2s linear infinite;
    }

    @keyframes beeInBottle {
        0% {
            transform: translateY(0) rotate(-20deg);
        }
        25% {
            transform: translateY(0.5vh) rotate(-20deg);
        }
        75% {
            transform: translateY(-0.5vh) rotate(-20deg);
        }
        100% {
            transform: translateY(0) rotate(-20deg);
        }
    }
</style>