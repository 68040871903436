import { ref } from "vue";
import { tg } from "@/utils/telegram";
import { getRequest, postRequest } from "./restapi";
import { profileData } from "./profile";
import { API_ADDRESS_SERVER } from "@/config";

export const beesDatasList = ref([]);
export const boostersDatasList = ref([]);

export const getListItems = async () => {
    beesDatasList.value = [];
    boostersDatasList.value = [];

    let bees = await getRequest(`${API_ADDRESS_SERVER}/market/get/bee/all`, true);

    bees.forEach(beeData => {
        beesDatasList.value.push({beeData})
    });

    let boosters = await getRequest(`${API_ADDRESS_SERVER}/market/get/booster/all`, true);

    boosters.forEach(boosterData => {
        boostersDatasList.value.push({boosterData})
    });
};

export const buyBee = async (idUser, idBee, price) => {
    if (profileData.value.serverData.balance.ton >= price) {
        let result = await postRequest(`${API_ADDRESS_SERVER}/market/buy/bee/${idUser}/${idBee}`)
        return result;
    } else {
        if (tg.platform === "unknown") {
            alert("Not enough TON!");
        } else {
            tg.showAlert("Not enough TON!");
        }
        return null;
    }
};

export const buyBooster = async (idUser, idBooster, price) => {
    if (profileData.value.serverData.balance.ton >= price) {
        let result = await postRequest(`${API_ADDRESS_SERVER}/market/buy/booster/${idUser}/${idBooster}`)
        return result;
    } else {
        if (tg.platform === "unknown") {
            alert("Not enough TON!");
        } else {
            tg.showAlert("Not enough TON!");
        }
        return null;
    }
};