import { ref } from 'vue';
import { accountId, updateData } from './user';
import { tonConnect, tonDisconnect, currentIsConnectedStatus } from './tonConnect';
import { getRequest } from './restapi';
import { API_ADDRESS_SERVER } from '@/config';

export const profileData = ref(null); 
export const connector = ref(null);
export const connectedWallet = ref(null);

export const requestProfileData = async () => {
    let data = null;

    data = await updateData();

    if (data !== null)
    {
        let bees = 0;
        let invites = 0;
        let notifications = [];

        if (data.bees !== null && data.bees.length > 0) {
            data.bees?.forEach(bee => {
                bees += bee.count;
            });
        }
        
        if (data.referrals !== null && data.referrals.length > 0) {
            invites = data.referrals.length;
        }

        if (data.notifications !== null) {
            notifications = data.notifications;
        }

        profileData.value = {
            serverData: data,
            totalBees: bees,
            amountCollect: data.earnings.amountCollect,
            income: data.earnings.income,
            countInvited: invites ?? 0,
            notifications: notifications
        };
    }
};

export const connectToWallet = async (isOpenModal = true) => {
    if (profileData.value && profileData.value.serverData) {
        connector.value = await tonConnect(profileData.value.serverData._id);

        if (!currentIsConnectedStatus.value) {
            connector.value.connectionRestored.then(async restored => {
                if (restored) {
                    console.log(
                        'Connection restored. Wallet:',
                        JSON.stringify({
                            ...connector.value.wallet,
                            ...connector.value.walletInfo
                        })
                    );
                } else {
                    console.log('Connection was not restored.');
                    if (isOpenModal) {
                        connectedWallet.value = await connector.value.openModal();
                    }
                }
            });
            console.log(connectedWallet.value);
        }
    }
};

export const disconnectWallet = async () => {
    if (currentIsConnectedStatus.value)
    {
        connectedWallet.value = await tonDisconnect();
        // console.log(connectedWallet.value);
    }
};

export const load = async () => {
    await requestProfileData();
    await connectToWallet(false);
};

export const getBalance = async () => {
    await load();
    
    let balance =  {
        ton: profileData.value?.serverData?.balance.ton ?? 0,
        honey: profileData.value?.serverData?.balance.honey ?? 0,
    };

    return balance;
};

export const checkWorkBees = async () => {
    await updateData();
    let data = await getRequest(`${API_ADDRESS_SERVER}/bees/work/check/${accountId.value}`);
    // console.log(JSON.stringify(data));
    await load();
    return data;
}

export const collectWorkBees = async () => {
    await updateData();
    let data = await getRequest(`${API_ADDRESS_SERVER}/bees/work/collect/${accountId.value}`);
    // console.log(JSON.stringify(data));
    await load();
    return data;
}