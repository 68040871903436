import { API_ADDRESS_SERVER } from "@/config";
import { getRequest, postRequest } from "./restapi";
import { accountId } from "./user";

export const getStaking = async () => await getRequest(`${API_ADDRESS_SERVER}/staking/get/${accountId.value}`);
export const checkStaking = async () => await getRequest(`${API_ADDRESS_SERVER}/staking/check/${accountId.value}`);
export const setStaking = async (amount) => await postRequest(`${API_ADDRESS_SERVER}/staking/set/${accountId.value}/${amount}`);

export const hoursToHHMM = (hours) => {
    let h = Math.trunc(hours);
    let m = Math.abs(Math.round((hours - h) * 60));

    if (m >= 60) {
        m -= 60;
        h += 1;
    }

    return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
}