<template>
    <main class="empty_background">
        <img class="empty_bee" src="../../../public/bees/default.gif" alt="bee" />
        <div class="empty_honey">
            <span>Honey</span>
            <span>Harvest</span>
        </div>
    </main>
</template>

<style>
.empty_background {
    background-image: url('../../assets/images/woodback.jpg');
    position: absolute;
    background-repeat: round;
    background-size: cover;
    width: -webkit-fill-available;
    height: 100vh;
    font-family: 'PIXY';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    z-index: 10000;
}

.empty_honey {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F7B409;
    font-size: 10vw;
    gap: 10px;
}

.empty_bee {
    width: 30vw;
    height: auto;   
    transform: translateY(0) rotate(-20deg);
    animation: loadBee 4s ease-in-out forwards
}

@keyframes loadBee {
    0% {
        transform: translateY(0) rotate(20deg);
    }
    25% {
        transform: translateY(5vh) rotate(-20deg);
    }
    75% {
        transform: translateY(-5vh) rotate(-20deg);
    }
    100% {
        transform: translateY(0) rotate(20deg);
    }
}
</style>  