import router from "@/router";
import { tg } from "@/utils/telegram";

const errorConnectMessage = "Unable to establish a connection to the server... Try again later!";

export const getRequest = async (url, isIgnore404) => await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
        "AUTH": localStorage.getItem("AUTH")
    }
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            if (tg.platform === "unknown") {
                alert(errorConnectMessage);
            } else {
                tg.showAlert(errorConnectMessage);
            }
        } else if (response.status === 403) {
            router.push({ name: 'soon' });
        } else if (response.status !== 404) {
            response.json()
            .then(data => {
                if (tg.platform === "unknown") {
                    alert(JSON.stringify(data.error ?? data));
                } else {
                    tg.showAlert(JSON.stringify(data.error ?? data));
                }
            })
            .catch(e => {
                if (tg.platform === "unknown") {
                    alert(e);
                } else {
                    tg.showAlert(e);
                }
            });
        } else if (!isIgnore404) {
            if (tg.platform === "unknown") {
                alert(errorConnectMessage);
            } else {
                tg.showAlert(errorConnectMessage);
            }
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const postRequest = async url => await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
        "AUTH": localStorage.getItem("AUTH")
    }
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            if (tg.platform === "unknown") {
                alert(errorConnectMessage);
            } else {
                tg.showAlert(errorConnectMessage);
            }
        } else {
            response.json()
            .then(data => {
                if (tg.platform === "unknown") {
                    alert(JSON.stringify(data.error ?? data));
                } else {
                    tg.showAlert(JSON.stringify(data.error ?? data));
                }
            })
            .catch(e => {
                if (tg.platform === "unknown") {
                    alert(e);
                } else {
                    tg.showAlert(e);
                }
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const postWithBodyRequest = async (url, data) => await fetch(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        "AUTH": localStorage.getItem("AUTH")
    },
    body: JSON.stringify(data),
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            if (tg.platform === "unknown") {
                alert(errorConnectMessage);
            } else {
                tg.showAlert(errorConnectMessage);
            }
        } else {
            response.json()
            .then(data => {
                if (tg.platform === "unknown") {
                    alert(JSON.stringify(data.error ?? data));
                } else {
                    tg.showAlert(JSON.stringify(data.error ?? data));
                }
            })
            .catch(e => {
                if (tg.platform === "unknown") {
                    alert(e);
                } else {
                    tg.showAlert(e);
                }
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const putRequest = async url => await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
        "AUTH": localStorage.getItem("AUTH")
    }
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            if (tg.platform === "unknown") {
                alert(errorConnectMessage);
            } else {
                tg.showAlert(errorConnectMessage);
            }
        } else {
            response.json()
            .then(data => {
                if (tg.platform === "unknown") {
                    alert(JSON.stringify(data.error ?? data));
                } else {
                    tg.showAlert(JSON.stringify(data.error ?? data));
                }
            })
            .catch(e => {
                if (tg.platform === "unknown") {
                    alert(e);
                } else {
                    tg.showAlert(e);
                }
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const putWithBodyRequest = async (url, data) => await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
        "AUTH": localStorage.getItem("AUTH")
    },
    body: JSON.stringify(data)
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            if (tg.platform === "unknown") {
                alert(errorConnectMessage);
            } else {
                tg.showAlert(errorConnectMessage);
            }
        } else {
            response.json()
            .then(data => {
                if (tg.platform === "unknown") {
                    alert(JSON.stringify(data.error ?? data));
                } else {
                    tg.showAlert(JSON.stringify(data.error ?? data));
                }
            })
            .catch(e => {
                if (tg.platform === "unknown") {
                    alert(e);
                } else {
                    tg.showAlert(e);
                }
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});