<script setup>
import { ref, onMounted } from 'vue';
import { connect, tap } from '@/utils/touchGame';
import { getTranslate, loadLocalize } from "@/utils/user";
import router from '@/router';

const tasks = ref('loading...');
const staking = ref('loading...');

const touch = async () => {
    let chanse = (Math.random() * 100);

    let isDouble = chanse >= 80;
    let isMulty = isDouble ? chanse >= 90 : false;

    const barrel = document.querySelector('.barrel');
    const newText = document.createElement('div');
    newText.innerText = isMulty ? '+10' : isDouble ? '+5' : '+1';
    newText.className =  isMulty ? 'floating-text multy noselect' : isDouble ? 'floating-text double noselect' : 'floating-text noselect';

    const rect = barrel.getBoundingClientRect();
    const x = Math.random() * rect.width;
    const y = Math.random() * rect.height;
    newText.style.left = `${rect.left + x}px`;
    newText.style.top = `${rect.top + y - 100}px`;

    document.body.appendChild(newText);
    setTimeout(() => {
        newText.style.opacity = '0';
        newText.style.transform = 'translateY(100%)';
    }, 100);
    setTimeout(() => {
        newText.remove();
    }, 2100);

    const newHoney = document.createElement('img');
    newHoney.className = "barrel_honey noselect";
    newHoney.src = "../barrel/barrel_honey_animation.gif";
    newHoney.alt = "honey";

    setTimeout(() => {
        newHoney.remove();
    }, 650);

    const barrelButton = document.getElementById('barrel-button');
    barrelButton.appendChild(newHoney);

    document.getElementById('honey').innerText = Number(document.getElementById('honey').innerText) + 1;
    tap(isDouble, isMulty);
}

const loadTranslations = async () => {
    tasks.value = await getTranslate('tasks');
    staking.value = await getTranslate('stake');
};

const openTasks = async () => {
    await router.push({
        name: "tasks"
    });
};

const openStacking = async () => {
    await router.push({
        name: "stacking"
    });
};

onMounted(async () => {
    await loadLocalize();
    await loadTranslations();
    await connect();
});
</script>

<template>
    <main id="barrel_menu" class="barrel-menu">
        <div class="side_panel">
            <button class="side-button side-button-brown" @click="openTasks">
                <img class="side-icons" src="../../assets/images/scroll-info.png" alt="tasks" />
                <p>{{ tasks }}</p>
            </button>

            <button class="side-button side-button-green" @click="openStacking">
                <img class="side-icons" src="../../assets/images/bank.png" alt="bank" />
                <p>{{ staking }}</p>
            </button>
        </div>
        <button id="barrel-button" class="barrel" @click="touch">
            <!-- <img class="barrel_honey noselect" src="@/assets/images/barrel_honey_animation.gif" alt="honey"/> -->
            <img class="barrel-img noselect" src="@/assets/images/barrel_with_grass.png" alt="barrel"/>
        </button>
        <!-- <img class="barrel_ground noselect" src="@/assets/images/ground.png" alt="groud"/> -->
        <!-- <img class="barrel_grass noselect" src="@/assets/images/grass.png" alt="grass"/> -->
    </main>
</template>

<style>
.barrel-menu {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.barrel-container {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.barrel {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    max-height: 50vh;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-bottom: 10%;
    overflow: hidden;
    transition: all .2s ease-in-out;
}

.barrel:active {
	transform: scale(0.9);
	transition: .1s;
}

.barrel-img {
    height: 100%;
    width: 100%;
    z-index: 2;
}

.barrel_ground {
    position: absolute;
    width: 60%;
    max-width: 60%;
    max-height: 90%;
    padding-bottom: 10%;
    z-index: 1;
    pointer-events: none;
}

.barrel_grass {
    position: absolute;
    width: 60%;
    max-width: 60%;
    max-height: 90%;
    padding-bottom: 10%;
    z-index: 3;
    pointer-events: none;
}

.barrel_honey {
    position: absolute;
    width: 100%;
    max-width: 100%;
    max-height: 90%;
    padding-bottom: 20%;
    z-index: 4;
    pointer-events: none;
}

.floating-text {
    position: absolute;
    color: yellow;
    font-size: 16vw;
    font-weight: bold;
    animation: fadeOut 3s forwards;
    z-index: 20;
}

.double {
    color: rgb(255, 166, 0);
}

.multy {
    color: rgb(255, 106, 0);
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}
</style>