<script setup>
import { ref, onMounted } from "vue";
//import { tg } from "@/utils/telegram";
//import { currentIsConnectedStatus } from "@/utils/tonConnect";
import { /*connectToWallet,*/ getBalance, load, profileData, checkWorkBees } from "@/utils/profile";
import { getListItems, buyBee, buyBooster, beesDatasList, boostersDatasList } from "@/utils/market";
import { already_bought, blocked, honey, loadLoc, price, very_soon, getBeeTypeTranslation, getBoosterTitleTranslation } from "@/utils/localize";

const beesList = ref([]);
const boostersList = ref([]);

const isBeeFly = ref(false);
const isBoosterImpulse = ref(false);

const animateBee = (beeType) => {
    isBeeFly.value = true;
    const beeAnimation = document.getElementById('bee-animation');

    if (beeAnimation !== null) {
        beeAnimation.style.backgroundImage = `url(bees/${beeType}.gif)`;
        beeAnimation.style.display = 'block';

        beeAnimation.style.animation = 'marketBee 4s ease-in-out forwards';

        beeAnimation.addEventListener('animationend', () => {
            beeAnimation.style.display = 'none';
            beeAnimation.style.animation = '';
            isBeeFly.value = false;
            enableOverlay(false);
        });
    }
};

const animateBooster = (boosterType) => {
    isBoosterImpulse.value = true;
    const boosterAnimation = document.getElementById('booster-animation');

    if (boosterAnimation !== null) {
        boosterAnimation.style.backgroundImage = `url(boosters/${boosterType}.png)`;
        boosterAnimation.style.display = 'block';

        boosterAnimation.style.animation = 'marketBooster 2s ease-in-out forwards';

        boosterAnimation.addEventListener('animationend', () => {
            boosterAnimation.style.display = 'none';
            boosterAnimation.style.animation = '';
            isBoosterImpulse.value = false;
            enableOverlay(false);
        });
    }
};

const enableOverlay = (isEnable) => {
    const overlay = document.getElementById('overlay');

    if (overlay !== null) {
        overlay.style.display = isEnable ? 'block' : 'none';
    }
};

const buyItemBee = async (bee) => {
    enableOverlay(true);

    await load();

    // if (!currentIsConnectedStatus.value) {
    //     tg.showAlert("Please, connect to wallet!", async () => await connectToWallet());
    // } else {
    let result = await buyBee(profileData.value.serverData._id, bee._id, bee.price);

    if (result !== null) {
        animateBee(bee.type);
    }

    let balance = await getBalance();
    document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
    document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
    //}

    let user = await checkWorkBees();
    await updateBeesOnScreen(user);

    if (!isBeeFly.value) {
        enableOverlay(false);
    }
};

const buyItemBooster = async (booster) => {
    enableOverlay(true);

    await load();

    let result = await buyBooster(profileData.value.serverData._id, booster._id, booster.price);

    if (result !== null) {
        animateBooster(booster.type);
    }

    let balance = await getBalance();
    document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
    document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));

    profileData.value.serverData.boosters[booster._id - 1].is_active = true;

    if (!isBoosterImpulse.value) {
        enableOverlay(false);
    }
};

const removeBees = () => {
    let beesHeader = document.getElementsByClassName('bee_cell');
    while (beesHeader.length > 0) {
        beesHeader[0].parentNode.removeChild(beesHeader[0]);
    }
};

const updateBeesOnScreen = async (user) => {
    await getListItems();

    removeBees();

    user.bees?.forEach((bee) => {
        let beeType = beesDatasList.value.find((data) => data.beeData._id === bee.id)?.beeData.type || 'default';
        let beesUnderHeader = document.getElementById('bees_under_header');

        if (beesUnderHeader !== null && bee.count > 0 && bee.is_fly) {
            beesUnderHeader.innerHTML += `
                <div class="bee_cell">
                    <img class="bee_icon" src="bees/${beeType}.gif" alt="bee-${beeType}" /> 
                </div>`;
        }
    });
};

const preventCollapse = () => {
    if (window.scrollY === 0) {
        window.scrollTo(0, 1);
    }
};

const loadMarket = async () => {
    await load();
    await getListItems();

    beesList.value = beesDatasList.value;
    boostersList.value = boostersDatasList.value;

    // Переводим данные о пчелах и бустерах
    for (const bee of beesList.value) {
        bee.beeData.name = await getBeeTypeTranslation(bee.beeData.type);
    }

    for (const booster of boostersList.value) {
        booster.boosterData.title = await getBoosterTitleTranslation(booster.boosterData.type);
        booster.boosterData.description = await getBoosterTitleTranslation(booster.boosterData.type + '-discription');
    }

    await updateBeesOnScreen(profileData.value.serverData);
};

onMounted(async () => {
    await loadLoc();
    await loadMarket();

    const scrollableElement = document.querySelector(".scrollable-element");
    scrollableElement.addEventListener("touchstart", preventCollapse);
});
</script>

<template>
    <main class="market-background">
        <div id="overlay" class="overlay"></div>
        <div id="bee-animation" class="bee-animation"></div>
        <div id="booster-animation" class="booster-animation"></div>
        <div class="market-content-container scrollable-element" id="market">
            <div class="market-element" v-for="(booster, index) in boostersList" :key="index" id="market-item">
                <div class="name-market">
                    <img src="../../assets/images/paceka-full.png" alt="auto_collect" class="boost-market" />
                    <div class="properties">
                        <span class="properties-bee">{{ booster.boosterData.title }}</span>
                        <span>{{ booster.boosterData.description }}</span>
                    </div>
                </div>
                <div class="price">
                    <span>{{ price }}</span>
                    <template v-if="booster.boosterData.is_blocked">
                        <button class="btn-market-lock">
                            {{ very_soon }}
                        </button>
                    </template>
                    <template v-else>
                        <template v-if="profileData.serverData.boosters[booster.boosterData._id - 1].is_active">
                            <button class="btn-market-lock">
                                {{ already_bought }}
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn-market" @click="buyItemBooster(booster.boosterData)">
                                <img src="../../assets/images/ton.png" alt="ton" class="icon" />
                                {{ booster.boosterData.price }}
                            </button>
                        </template>
                    </template>
                </div>
            </div>

            <div class="market-element" v-for="(bee, index) in beesList" :key="index" id="market-item">
                <div class="name-market">
                    <img :src="`bees/${bee.beeData.type}.gif`" alt="bee" class="bee-market" />
                    <div class="properties">
                        <span class="properties-bee">{{ bee.beeData.name }}</span>
                        <span>{{ bee.beeData.ton }} TON</span>
                        <span>{{ bee.beeData.honey }} {{ honey }}</span>
                    </div>
                </div>
                <div class="price">
                    <span>{{ price }}</span>
                    <template v-if="bee.beeData.is_blocked">
                        <button class="btn-market-lock">
                            {{ blocked }}
                        </button>
                    </template>
                    <template v-else>
                        <button class="btn-market" @click="buyItemBee(bee.beeData)">
                            <img src="../../assets/images/ton.png" alt="ton" class="icon" />
                            {{ bee.beeData.price }}
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </main>
</template>

<style>
.market-background {
    height: 80%;
    width: 90vw;
    display: flex;
    background-image: url("../../assets/images/scroll-info.png");
    background-size: cover;
    flex-direction: column;
    background-repeat: round;
    flex-wrap: nowrap;
    align-items: center;
    padding: 17vw 0 17vw 0;
    object-fit: contain;
    overflow: hidden;
}

.price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #753f16;
    font-size: 3vw;
}

.market-content-container {
    width: 65vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2vw;
    overflow: overlay;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-right: 2vw;
}

.market-element {
    width: -webkit-fill-available;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5%;
}

.btn-market {
    width: 18vw;
    height: 8vw;
    background-image: url('../../assets/images/button_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    font-family: PIXY;
    gap: 1vw;
    font-size: 3vw;
}

.btn-market-lock {
    width: 18vw;
    height: 8vw;
    background-image: url('../../assets/images/button_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #753f15;
    font-family: PIXY;
    gap: 1vw;
    font-size: 2vw;
    box-shadow:inset 0px 0px 15px 5px #1b1b1b4d;
}

.bee-market {
    width: 14vw;
    height: 14vw;
    transform: rotate(-20deg);
}

.boost-market {
    width: 14vw;
    height: 14vw;
    padding: 4%;
}

.properties {
    display: flex;
    flex-direction: column;    
    color: #753f16;
    font-size: 3vw;
}

.properties-bee {
    font-size: 5vw;
}

.name-market {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bee-animation {
    position: fixed;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1000;
    display: none;
    transform: translate(0, 0) rotate(0);
}

@keyframes marketBee {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    100% {
        transform: translate(100vw, -10vh);
    }
}

.booster-animation {
    position: fixed;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1000;
    display: none;
    transform: translate(0, 0) rotate(0);
}

@keyframes marketBooster {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>