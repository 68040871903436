import { ref } from 'vue';
import { getRequest, postRequest } from './restapi';
import { tg } from "@/utils/telegram";
import { TonConnectUI, THEME } from '@tonconnect/ui';
import { beginCell, toNano } from '@ton/ton'
import { API_ADDRESS_SERVER } from "@/config";
import { error_get_wallet_address } from './localize';

const tonConnectUIInstance = ref(null);

export const currentWallet = ref(null);
export const currentWalletInfo = ref(null);
export const currentAccount = ref(null);
export const currentIsConnectedStatus = ref(false);

export const userDataWithWallet = ref(null);

export const tonConnect = async userId => {
    if (!tonConnectUIInstance.value) {
        tonConnectUIInstance.value = new TonConnectUI({
            manifestUrl: 'https://honeyharvest.ru/tonconnect-manifest.json'
        });

        tonConnectUIInstance.value.uiOptions = {
            twaReturnUrl: 'https://t.me/HoneyHarvestGame_Bot',
            language: 'eng',
            uiPreferences: {
                theme: THEME.DARK
            }
        };

        tonConnectUIInstance.value.onStatusChange(async () => {
            updateWalletInfo();
            // console.log(JSON.stringify(currentWallet.value));
            // console.log(JSON.stringify(currentWalletInfo.value));
            // console.log(JSON.stringify(currentAccount.value));
            // console.log(currentIsConnectedStatus.value);
            if (currentWalletInfo.value !== null && currentAccount.value !== null) {
                userDataWithWallet.value = await addWalletDataToUser(userId);
                //alert(JSON.stringify(userDataWithWallet.value));
            }
        });
    }

    return tonConnectUIInstance.value;
}

export const tonDisconnect = async () => {
    if (tonConnectUIInstance.value) {
        await tonConnectUIInstance.value.disconnect();
    }

    try {
        updateWalletInfo();
        return tonConnectUIInstance.value;
    } catch (e) {
        tg.showAlert(e);
        console.error(e);
    }
}

export const updateWalletInfo = () => {
    currentWallet.value = tonConnectUIInstance.value.wallet;
    currentWalletInfo.value = tonConnectUIInstance.value.walletInfo;
    currentAccount.value = tonConnectUIInstance.value.account;
    currentIsConnectedStatus.value = tonConnectUIInstance.value.connected;
};

export const addWalletDataToUser = async userId => await postRequest(`${API_ADDRESS_SERVER}/user/wallet/connect/${userId}/${currentWalletInfo.value.appName}/${currentAccount.value.address}`);

export const sendTransaction = async (price, itemName) => {
    let data = await getRequest(`${API_ADDRESS_SERVER}/wallet/address/get`);

    if (data !== null && (data.address !== null && data.address !== "")) {
        let walletAddress = data.address;

        let body = beginCell()
            .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
            .storeStringTail(`${itemName}`) // write our text comment
            .endCell();
    
        let transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            messages: [
                {
                    address: walletAddress,
                    amount: toNano(price).toString(),
                    payload: body.toBoc().toString("base64") // payload with comment in body
                }
            ]
        }
    
        try {
            let result = await tonConnectUIInstance.value.sendTransaction(transaction);
            return result;
        } catch (e) {
            tg.showAlert(e);
            console.error(e);
            return null;
        }
    } else {
        tg.showAlert(error_get_wallet_address.value);
    }
    
};